import { Row,Text } from "../../style"
import { Colors } from "../../theme/colors"

interface PriceComponentProps{
    price:number,
    retailPrice:number,
    currency:string,
    align?:"flex-start" | "center"
}

export const PriceComponent = ({price,retailPrice,currency,align}:PriceComponentProps)=>{
    const dir = align || "center"
    return (
        <Row gap="10px" justify={dir}>
        <Text weight={600} background={Colors.primary} color={Colors.lightPrimary}>{currency+price}</Text>
        {
            price<retailPrice &&
            <Text $fontSize="16px" decoration="line-through" weight={500} color={Colors.blackText}>{currency+retailPrice}</Text>
        }
        

        </Row>
    )
}
