import { Col } from "../../style"
import { DropDownBox } from "../generic/DropDownBox"

interface DescriptionAreaProps {
    shopItem:ShopItem
}

export const DescriptionArea = ({shopItem}:DescriptionAreaProps)=>{
    return (
        <Col margin="0px 0px 25px 0px">
         <DropDownBox title={"Description"} content={shopItem.description} bullets={shopItem.descriptionBullets}/>
         <DropDownBox title={"Ingredients"} bullets={shopItem.ingredients}/>
        </Col>
    )
} 