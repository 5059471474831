import * as React from "react";

import { useState } from "react";
import { useDeviceType } from "../../context/DeviceContext";
import { DataArea, DataProps } from "../data/DataArea";
import {  RequestConfigs } from "./../../api";
import { DataLayoutContainer, CloseButton,SidebarContainer,FilterContainer,Spacer,Head ,DataContainer,Body} from "./index.style";
import { useFormHandling } from "../../hooks/FormHandle";
import { Filter } from "./filter";
import { useFilter } from "../../context/FilterContext";
import { FieldType, TextFieldType } from "../fields";
import Joi from "joi";
import { ItemPreview } from "./itemPreview";
import { WrappedRow } from "../../style";






export const Shop = () => {

  
  const {isMobile} = useDeviceType()
  const {showFilter,setShowFilter} = useFilter()
  const { handleInputChange, handleClick, serverRes, formValues,submitted } = useFormHandling({ requestType: RequestConfigs.getShop,params:{campaignId:process.env.REACT_APP_CAMPAIGN_ID} })

  const fields:FieldConfig[] = [
    {
      type: FieldType.text,
      name: "search",
      label: "Search",
      joiSchema: Joi.string().allow(null, ''),
      typeOptions:{type:TextFieldType.search}
    },
    {
      type: FieldType.multiSelect,
      name: "tags",
      label: "Good For:",
      joiSchema: Joi.array(),
      typeOptions:{
        options:[],
      }
    },
  
   
  ]
  React.useEffect(() => {
    
    handleClick()

  }, [formValues]);
  
  // React.useEffect(() => {
    
  //   handleClick()

  // }, []);


  if(serverRes?.tags){
    fields[1].typeOptions.options=serverRes.tags
  }

 




  const data = serverRes?.shopItems


  

  return (
    
    <DataLayoutContainer>
      
      {
        !isMobile &&
        <FilterContainer>
        <Filter submitted={submitted} fields={fields} serverRes={serverRes} handleInputChange={handleInputChange} />
        </FilterContainer>
      }
  

      {
        isMobile && 
        <SidebarContainer $isOpen={showFilter}>
          <CloseButton onClick={()=>setShowFilter(false)}/>
          <Filter submitted={submitted} fields={fields} serverRes={serverRes} handleInputChange={handleInputChange} />
        </SidebarContainer>
        
      }
      <Head>
      
      <DataContainer id="data-container" $isMobile={isMobile}>
      <WrappedRow justify="flex-start" id="body-container">
        <>
        {
        data && data.map((item,index) => (
          <ItemPreview shopItem={item}  key={index}/>

        ))}

        </>
        
      </WrappedRow>
  
  </DataContainer>  
        </Head>
    
    </DataLayoutContainer>
  );
};

