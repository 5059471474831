
import styled from "styled-components";
import { Colors } from "../../theme/colors";
import { useState } from "react";
import { useDeviceType } from "../../context/DeviceContext";
import { Icons } from "../../constants/images";
import { Bullets } from "./Bullets";


export interface BoxProps {
    title:string;
    content?:string;
    bullets?:string[]
}



export const DropDownBox = ({title,content,bullets}: BoxProps) => {
    const [isCollapsed,setIsCollapsed] = useState<boolean>(true)
    const {isMobile} = useDeviceType()

    const getContent = ()=>{
      return (
        <>
        {
          content &&
          <Text>{content}</Text>
        }
        {
          bullets &&
          <Bullets rows={bullets}/>
        }
         
        </>
      )
    }
  
  return (
   <BoxContainer $isMobile={isMobile}>
    <TitleContainer>
        <Title>{title}</Title>
        <ToggleIcon src={isCollapsed ? Icons.Down : Icons.Up} alt="Toggle" onClick={()=>{setIsCollapsed(!isCollapsed)}} />

    </TitleContainer>
   {!isCollapsed && 
    getContent()
   } 
   
   </BoxContainer>
  );
};


const BoxContainer = styled.div<{$isMobile:boolean}>`
width: 90%;
background-color: ${Colors.whiteBackground};
justify-content: flex-start;
box-sizing: border-box;
/* height: 150px; */
margin-top:20px;
padding: 20px;
border-radius: 8px;
box-shadow:    0px 4px 4px ${Colors.darkShadow};
border: 1px solid ${Colors.fieldBorder};
`
const TitleContainer = styled.div`
    display: flex;
    justify-content: space-between;
`

const Title = styled.h3`
/* padding: 20px 10px 0px 20px; */
margin: 0;
color:${Colors.text};
`

const Text = styled.p`
/* padding: 0px 10px 0px 20px; */
font-size: 16px;
color:${Colors.text};
`

const ToggleIcon = styled.img`
  /* padding: 20px 20px 0px 20px; */
  cursor: pointer;
  width: 20px;
  height: 20px;
  margin-left: 5px;
`;







