import { Backgrounds, Brand, Images } from "../../constants/images";
import styled from "styled-components";
import { Container, Col, Row, Title, Text, Btn, Img, WrappedRow, } from "../../style";
import { useDeviceType } from "../../context/DeviceContext";
import { Colors } from "../../theme/colors";

export const Strip = () => {
    const { isMobile } = useDeviceType()
    return (
        <Section>
            <Container>
                <WrappedRow >
                    <Col align="start" $maxWidth="500px" className="paragraph">
                        <Col justify="center" align={isMobile?"center":"start"} $maxWidth="600px" gap="10px">
                            <Title $isMobile={isMobile} $fontSize={23} $lineHeight={30} weight={400}>
                            Start Your Journey Towards Better Health

                            </Title>
                            <Title $isMobile={isMobile} $fontSize={30} $lineHeight={50} weight={700}>
                                Daily Health & Wellness
                            </Title>
                           
                          



                        </Col>

                    </Col>
               

                    <Col align="center" $maxWidth="200px">
                        <Img src={Images.BottlesGroup} width={150}/>
                    </Col>

              
                </WrappedRow>

            </Container>

        </Section>
    )
}



const Section = styled.div<{ $zIndex?: number, padding?: string }>`
  margin:20px 0px;
  padding:${props => props.padding || '10px 0px'};
  position: relative;
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  box-sizing: border-box;
  overflow: hidden; 
  z-index: ${props => props.$zIndex !== undefined ? props.$zIndex : 0};

`;

const FloatImg=styled.img`
position: absolute;
/* top:30px; */

z-index: -5;
opacity: 0.7; // Adjust the opacity value for desired transparency
`
