import React from "react";
import { TextLink } from "./TextLink";
import styled from "styled-components";
import { StyledHR } from "../../style";

interface LinksListProps {
    links: LinksListItem[];
    fontSize?: string;
    fontColor?: string;
    fontWeight?: number;
    closeMenu?:()=>void
}

export interface LinksListItem {
    text: string;
    to: string;
}

export const MenuLinks: React.FC<LinksListProps> = ({ links, fontSize, fontColor, fontWeight,closeMenu }) => {
    return (
        <Container>
           {links.map((link, index) => (
                <React.Fragment key={index}>
                    <Item>
                        <TextLink
                            route={link.to}
                            fontSize={fontSize}
                            fontColor={fontColor}
                            fontWeight={fontWeight}
                            onClick={closeMenu}
                        >
                            {link.text}
                        </TextLink>
                        <StyledHR $height={1}/>
                    </Item>
                   
                </React.Fragment>
            ))}
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 85%;
`;

const Item = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
  
    margin: 0 8px;
  
    
    
    /* margin: 0px 10px 0px 0px; */
    
`;

const Separator = styled.span`
    
    color: #000; // You can adjust this color as needed
    display: inline-block;
    &:last-child {
        display: none;
    }
    
  
`;

