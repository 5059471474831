import { useEffect } from "react";
import { Footer } from "../components/layout/Footer";
import { Header } from "../components/layout/Header";
import { HomeComponent } from "../components/home";
import { CartComponent } from "../components/cart";
import { Col, Img } from "../style";
import { Images } from "../constants/images";
import { useDeviceType } from "../context/DeviceContext";
import { BaseLayout } from "../components/layout/BaseLayout";


export const Ingredients = () => {
    const {isMobile} = useDeviceType()
    return (
        <BaseLayout>
            <Col>
                <Img width={isMobile?"100%":"600px"} src={Images.Ingredients}/>
            </Col>
        </BaseLayout>
        
        
    )
  
};
