import { useEffect, useState } from "react";
import styled from "styled-components";
import Joi from "joi";
import { BaseLayout } from "../components/layout/BaseLayout";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDeviceType } from "../context/DeviceContext";
import { useCustomer } from "../context/CustomerContext";
import { Shop } from "../components/shop";
import { ShopItemComponent } from "../components/shop/shopItem";


export const ProductDetailsPage = () => {

    const location = useLocation()
    const { customer } = useCustomer()
    const { isMobile } = useDeviceType()
    const { id } = useParams();

    useEffect(() => {
        if (location.hash) {
            const element = document.getElementById(location.hash.substring(1));
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [location]);


    return (
        <BaseLayout>
        <ContentArea $isMobile={isMobile}>
            <ShopItemComponent/>
        </ContentArea>
            
        </BaseLayout>
    )




}


const ContentArea = styled.div<{$isMobile:boolean}>`
    margin-left: ${props=>props.$isMobile?"":"220px"};
    margin-right: ${props=>props.$isMobile?"":"220px"};
`