import { Backgrounds, Images } from "../../constants/images";
import styled from "styled-components";
import { Container, Col, Row, Title, Text, Btn, Img, Section} from "../../style";
import { useDeviceType } from "../../context/DeviceContext";

export const Wellness = () => {
    const { isMobile } = useDeviceType()
 
    return (
        <Section $backgroundColor1="white" id="about">
            <Col>
            {/* <FloatImg src={Images.BackInStock} width={200}/> */}
            
            </Col>
            <Container>
                <Row>
                    { !isMobile &&
                    <Col align="start">
                        <Img src={Images.Avocado} width={650}/>
                    </Col>
                    }
                    
                    <Col align="end">
                        <Col justify="center" align="start" $maxWidth="600px" gap="10px">
                            <Title $isMobile={isMobile} $fontSize={23} $lineHeight={30} weight={400}>
                            Wellness with
                            </Title>
                            <Title $isMobile={isMobile} $fontSize={40} $lineHeight={50} weight={700}>
                            SlimmyGummy
                            </Title>
                            <Img src={Images.LineLft}/>
                            
                            
                            <Col  $maxWidth="550px" margin="0px 0px 20px 0px" gap="20px">
                            { isMobile &&
                                 <FloatImg src={Images.Avocado} width={600}/>
                            }
                                <Text>
                                Those who are committed to improving their health with dedication can often see tangible results. However, it’s common to feel overwhelmed by the myriad of routines available, leading to confusion in the pursuit of a healthy lifestyle goals. Advanced nutritional support can provide the essential advantage needed to stay on track and hit those goals. 
                                </Text>
                                <Text>
                                <b>SlimmyGummy</b> introduces a selection of advanced wellness ingredients through expertly crafted, easy-to-use food supplements.
                                </Text>
                                <Text>
                                    Each formulation is optimally fortified, effective and enriched with high quality ingredients that may help support your journey toward optimal health and well-being.
                                </Text>
                            </Col>
                            <Btn width="240px" to={"/#shop"}>place order</Btn>



                        </Col>

                    </Col>
                </Row>

            </Container>

        </Section>
    )
}






const FloatImg=styled.img`
position: absolute;
top:0;
right: 0;
z-index: -5;
filter: blur(5px); // Adjust the blur intensity
opacity: 0.7; // Adjust the opacity value for desired transparency
`
