import React, { createContext, useContext, useState, ReactNode } from 'react';

type FilterContextType = {
  showFilter: boolean;
  setShowFilter: (show: boolean) => void;
};

const FilterContext = createContext<FilterContextType | undefined>(undefined);

interface FilterProviderProps {
  children: ReactNode;
}

export const FilterProvider: React.FC<FilterProviderProps> = ({ children }) => {
  const [showFilter, setShowFilter] = useState<boolean>(false);

  return (
    <FilterContext.Provider value={{ showFilter, setShowFilter }}>
      {children}
    </FilterContext.Provider>
  );
};

export const useFilter = () => {
  const context = useContext(FilterContext);
  if (context === undefined) {
    throw new Error('useFilter must be used within a FilterProvider');
  }
  return context;
};
