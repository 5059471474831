import bottleImage from "../assets/brand/bottle.png"
import logo from "../assets/brand/logo.png"
import stripBottles from "../assets/brand/strip_bottles.png"
import Exclamation from "../assets/icons/exclamation.svg"
import Success from "../assets/icons/success.svg"
import Eye from "../assets/icons/eye.svg"
import Error from "../assets/icons/error.svg"
import Login from "../assets/icons/login.svg"
import Search from "../assets/icons/search.svg"
import CartRemove from "../assets/icons/cart-remove1.png"
import Cart from "../assets/icons/cart-ic.png"
import Mail from "../assets/icons/mail.jpg"
import Phone from "../assets/icons/phone.jpg"
import Time from "../assets/icons/time.png"
import GreenV from "../assets/icons/green-v.png"
import X from "../assets/icons/x.svg"
import Points from "../assets/icons/points.svg"
import Cash from "../assets/icons/cash.svg"
import Credit from "../assets/icons/credit.svg"
import Star from "../assets/icons/rank_star.svg"
import HeroBackground from "../assets/backgrounds/banner.jpg"
import Wellness from "../assets/backgrounds/wellness.png"
import Coins from "../assets/backgrounds/coins.png"
import LineLft from "../assets/images/hd-line-lft.png"
import LineCtr from "../assets/images/hd-line-ctr.png"
import HealthImg1 from "../assets/images/s3-img1.png"
import HealthImg2 from "../assets/images/s3-img2.png"
import HealthImg3 from "../assets/images/s3-img3.png"
import HealthImgIcon1 from "../assets/images/s3-lst1.png"
import HealthImgIcon2 from "../assets/images/s3-lst2.png"
import HealthImgIcon3 from "../assets/images/s3-lst3.png"
import Ingredients from "../assets/images/ingredients.png"
import Visa from "../assets/images/visa.png"
import Mastercard from "../assets/images/mastercard.png"
import Amex from "../assets/images/amex.png"
import LoyaltyBg from "../assets/backgrounds/loyalty-bg.png"
import StarIcon from "../assets/icons/star.svg";
import Arrow from "../assets/icons/arrow.svg";
import VList from "../assets/icons/v-list.svg";
import DeliveryIcon from "../assets/icons/delivery_icon.svg";
import Renewal from "../assets/icons/renewal.png";
import Up from "../assets/icons/up.svg";
import Down from "../assets/icons/down.svg";
import OutOfStock from "../assets/images/out-of-stock.png";
import BackInStock from "../assets/images/back-in-stock.png";
import BottlesGroup from "../assets/images/bottles-group.png";
import Avocado from "../assets/images/avocado.png";



export const Brand = {
    bottleImage,
    logo,
    stripBottles
}

export const Images = {
    LineLft,
    LineCtr,
    HealthImg1,
    HealthImg2,
    HealthImg3,
    HealthImgIcon1,
    HealthImgIcon2,
    HealthImgIcon3,
    Ingredients,
    Visa,
    Mastercard,
    Amex,
    OutOfStock,
    BottlesGroup,
    BackInStock,
    Avocado
}

export const Backgrounds = {
    HeroBackground,
    Wellness,
    LoyaltyBg,
    Coins
}

export const Icons = {
    Exclamation,
    Success,
    Eye,
    Star,
    Error,
    Search,
    Cart,
    VList,
    CartRemove,
    Mail,
    Phone,
    Time,
    GreenV,
    Login,
    Points,
    Credit,
    StarIcon,
    Cash,
    X,
    Arrow,
    DeliveryIcon,
    Renewal,
    Down,
    Up
}