import React from "react";
import { Col,Text } from "../../style"



export const SubTerms:React.FC = ()=>{
    
    return (
        <>
        <Col>
        <Text>
                        All chosen packages are part of our unique wellness journey monthly subscription program. To ensure that your experience is not interrupted, approximately 5 days before the renewal of your chosen package subscription cycle, you will receive a refill notification email to notify you that a fresh refill of the product and quantity that you originally ordered is being prepared to be shipped. You agree to be charged the same low price per bottle of the product as in your original order. You will be charged the same price for a lower quantity of bottles, excluding the free bottles from your original chosen package. 
                    </Text>
                    <Text >* By placing your monthly recurring order of Keto+AVC (2 pack) MRP- you will be charged $119.95 Now and every 30 days thereafter until you cancel your subscription. You will receive an electronic notification 5 to 7 days prior to your transaction and receipt after each successful transaction.</Text>
                    <Text>* By placing your monthly recurring order of Keto+AVC (2+1 Free) MRP- you will be charged $159.95 Now and every 30 days thereafter until you cancel your subscription. You will receive an electronic notification 5 to 7 days prior to your transaction and receipt after each successful transaction.</Text>
                    <Text>* By placing your monthly recurring order of Keto+AVC (3+2 Free) MRP- you will be charged $199.95 Now and every 30 days thereafter until you cancel your subscription. You will receive an electronic notification 5 to 7 days prior to your transaction and receipt after each successful transaction.</Text>
        </Col>
        </>
    )
}