import React from 'react';
import styled, { keyframes } from 'styled-components';
import { Colors } from '../../theme/colors';

// Keyframes for animating the progress bar
const fillAnimation = (width:string) => keyframes`
  0% {
    width: 0%;
  }
  100% {
    width: ${width}%;
  }
`;

// Styled component for the progress bar container
const ProgressBarContainer = styled.div`
  width: 100%;
  height: 30px;
  background-color: #e0e0df;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: inset 0px 0px 5px #aaa;
`;

// Styled component for the progress bar
const Progress = styled.div<{percentage:number}>`
  height: 100%;
  width: ${props => props.percentage}%;
  background: linear-gradient(to right, ${Colors.secondary}, #4caf50); /* Dark green to light green gradient */
  border-radius: 5px;
  transition: width 0.4s ease;
  animation: ${props => fillAnimation(props.percentage.toString())} 1s ease forwards;
`;

// React component to display the progress bar

interface PercentageProgressBarProps {
    value:number

}
export const PercentageProgressBar = ({ value }:PercentageProgressBarProps) => {
  return (
    <ProgressBarContainer>
      <Progress percentage={value} />
    </ProgressBarContainer>
  );
};


