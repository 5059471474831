import styled from "styled-components";
import { Colors } from "../../theme/colors";
import { Icons } from "../../constants/images";



export const DataLayoutContainer = styled.div`
display: flex;
gap:20px;
width: 100%;
`;

export const Head = styled.div`
display: flex;
flex-direction: column;
gap:20px;
width: 100%;
`;

export const SidebarContainer = styled.div<{ $isOpen: boolean }>`
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: ${props=>props.$isOpen? "0" : "-100%"};
  width: 100%;
  height: 100%;
  background-color: ${Colors.whiteBackground};
  z-index: 1000;
  transition: left 0.3s ease-in-out;
`;

export const CloseButton = styled.button`
  position: absolute;
  right:10px;
  top:15px;
  width: 36px;
  height: 36px;
  background-color: transparent;
  background-image: url(${Icons.X});
  background-size: cover;
  border: none;
  cursor: pointer;
`;


export const FilterContainer = styled.div`
  flex: 0 0 30%;
  max-width: 320px;
`;
export const Spacer = styled.div`
  margin: 10px;
`;




export const Body = styled.div`
 display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  width: 100%;
`;

export const DataContainer = styled.div<{$isMobile:boolean}>`
   flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-left: ${props=>props.$isMobile? '20px' : 'none'};
  margin-right: ${props=>props.$isMobile? '20px' : 'none'};
  margin-top: ${props=>props.$isMobile? '20px' : 'none'};
`;


export const FilterForm = styled.div<{$isMobile:boolean}>`
  display: flex;
  justify-content: center;
  border:${prop=>prop.$isMobile?'none':'0px solid '+ Colors.fieldBorder};
  padding: 15px;
  border-radius: 8px;
  background-color: ${Colors.whiteBackground};
`;

export const DataRegContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  border-radius: 8px;
  border: solid 1px ${Colors.fieldBorder};

  background-size: cover;
  background-position: center; // Ensure the background is centered
`;


export const BodyReg = styled.div`
  display: flex;
  flex-direction: column;
  padding: 50px;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
`;

export const Title = styled.h1``

export const Text = styled.p`
font-size: 16px;
`

// Styled components
export const SwitchContainer = styled.div`
  display: flex;
  border-radius: 8px;
  border: 1px solid ${Colors.fieldBorder};
  background-color: ${Colors.whiteBackground};
  position: relative;  
  justify-content: center;  
  cursor:pointer ;
`;

export const Button = styled.button`
  flex: 1;
  height: 44px;
  border: none;
  background-color: transparent;
  color: ${Colors.text};
  border-radius: 8px;
  text-align: center;
  transition: background-color 0.3s, color 0.3s;
  cursor:pointer ;
  &:focus {
    outline: none;
  }
`;

export  const ActiveButton = styled(Button)`
  background-color: ${Colors.primary};
  color: ${Colors.whiteBackground};
`;



export const PageButton = styled.button<{ $isActive?: boolean }>`
  background: ${props => props.$isActive ? 'none' : 'none'};
  color: ${Colors.text};
  border: ${props => props.$isActive ? '1px solid '+Colors.primary : 'none'}; 
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  padding: 5px 10px;
  border-radius: 8px;

  &:disabled {
    color:${Colors.primary};
    cursor: default;
  }

  &:not(:disabled):hover {
    /* background-color: ${Colors.primary}; */
    color:  ${Colors.primary};
  }
`;


export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
  margin-top: 10px;
`;

export const PagesContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;







///////////////////


export const SubscribeContainer = styled.div`
display: flex;
flex-direction: column;
justify-content:center;
align-items: center;
border: 1px solid ${Colors.fieldBorder};
background-color: ${Colors.whiteBackground};
padding: 20px;
border-radius: 8px;
`

export const Icon = styled.img`
`
export const Svg = styled.svg`
fill: ${Colors.primary};
`
export const Buttons=styled.div`
display: flex;
justify-content: flex-end;
width: 100%;
margin-top: 15px;
gap:10px;
`

export const BigText = styled.p`
font-size: 18px;
`

export const SubText = styled.p`
font-size: 14px;
`

export const RecordsInfo = styled.span`
  font-size: 14px;
  color:${Colors.text};
`;












