import React from 'react';
import styled from 'styled-components';

import { Colors } from '../../theme/colors';
import { Icons } from '../../constants/images';
import { number } from 'joi';




// SocialPanel component to house the social login options

interface StarsProps{
  score:number,
  scoreCount:number
  size?:number
}
export const Stars: React.FC<StarsProps> = ({score,scoreCount,size}) => {
  const mysize = size || 1
  const generateStars = ()=>{
    const totalStars = Math.round(score)
    const icons = []
    for (let i = 0; i < totalStars; i++) {
      icons.push(<Icon src={Icons.Star} key={i} size={mysize}/>)
  }
  return icons
  }

  return (
    <StarsContainer size={mysize}>
      <Text>{score}</Text>
      {generateStars()} 
      <Text>{"("+scoreCount+")"}</Text> 
    </StarsContainer>
  );
};


// Styled container for the social login buttons
const StarsContainer = styled.div<{size:number}>`
  display: flex;
  justify-content: center;
  gap: 5px;
  font-size:${props=>props.size*14}px;
  color: ${Colors.text};
`;

const Icon = styled.img<{size:number}>`
width: ${props=>props.size*14}px;
`

const Text = styled.div`
`
