import styled from "styled-components";
import { Colors } from "../../theme/colors";


interface TagsProps {
    rows:string[]
    fontSize?:string;
}

export const Tags: React.FC<TagsProps> = ({ rows, fontSize }) => {



   


    const generateBullets = () => {
        return rows.map((row, index) => {
            
            
            return (
                <V key={index}>
                    {row}
                </V>
            );
        });
    };

    

    return (
        <>
            <VPoint $fontSize={fontSize}>{generateBullets()}</VPoint>
        </>
    );
};




const VPoint = styled.ul<{$fontSize?:string}>`
display: flex;
flex-wrap: wrap;
justify-content: flex-start;
gap: 5px 15px;
padding: 0;
font-size: ${props=>props.$fontSize?props.$fontSize:"14px"};
color: ${Colors.text};
margin: 5px;
`
const V = styled.li`
text-transform: capitalize;
margin-left: 10px;
padding: 0;`


