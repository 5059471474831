import { useEffect, useState } from "react";
import { RequestConfigs } from "../../api";
import { Icons } from "../../constants/images";
import { useDeviceType } from "../../context/DeviceContext";
import { useFormHandling } from "../../hooks/FormHandle";
import { Container,Col,WrappedRow,Img,Title } from "../../style"
import { PercentageProgressBar } from "../generic/ProgressBar";
import DynamicTable from "../generic/Table";
import styled from "styled-components";
import { Colors } from "../../theme/colors";
import { CompactTable } from '@table-library/react-table-library/compact';
import { TableComponent } from "../generic/TableComponent";

export const RoyaltyStats = ()=>{
    const {isMobile} = useDeviceType()
    const {  handleClick,  serverRes } = useFormHandling({ requestType: RequestConfigs.getRoyalty })
    const [points,setPoints] = useState<number>(0)
    const [credit,setCredit] = useState<number>(0)
    const [orders,setOrders] = useState<RoyaltyOrders[]>([])
    const [upcommingOrders,setUpcommingOrders] = useState<UpcommingOrders[]>([])

    const ordersColumns = [
        { id:"orderId",label: 'Order Id', renderCell: (item:RoyaltyOrders) => item.orderId },
        {id:"orderDate",
          label: 'Order Date',
          renderCell: (item:RoyaltyOrders) =>
            (new Date(item.orderDate)).toLocaleDateString(),
        },
        { id:"pointsGained",label: 'Points Gained', renderCell: (item:RoyaltyOrders) => item.pointsGained },
        { id:"pointsRedeemed",label: 'Points Redeemed', renderCell: (item:RoyaltyOrders) => item.pointsRedeemed },
        { id:"creditApplied",label: 'Credit Applied', renderCell: (item:RoyaltyOrders) => item.creditApplied },
      ];
    const upcommingColumns = [
        { id:"orderDate",label: 'Order Date', renderCell: (item:RoyaltyOrders) =>
            (new Date(item.orderDate)).toLocaleDateString(),},
        { id:"productName",label: 'Product'},
        { id:"nextBillDate",label: 'Upcomming Refill'},
        { id:"futurePoints",label: 'Credit Applied'},
        
    ]
    
  
   

    useEffect(() => {
        handleClick()
    }, []);


    useEffect(() => {
        if (serverRes?.succeeded && serverRes?.royaltyOrders) {
          
            setOrders(serverRes?.royaltyOrders.sort((a, b) => new Date(b.orderDate).getTime() - new Date(a.orderDate).getTime()))
        }
        if (serverRes?.succeeded && serverRes?.upcommings) {
            setUpcommingOrders(serverRes?.upcommings.sort((a, b) => new Date(b.orderDate).getTime() - new Date(a.orderDate).getTime()))
        }
        if (serverRes?.succeeded && serverRes?.customer) {
            setPoints(Number(serverRes?.customer.custom2))
            setCredit(Number(serverRes?.customer.creditAmount))
        }
    }, [serverRes]);

    

    return (
        <>
          <Container $gap="50px" padding="50px 0px">
                 
                    <Col align="start" gap="20px">
                        <Title dir={"start"} $isMobile={isMobile} $fontSize={20}>Future Points</Title>
                        <TableComponent idColumn="orderId" columns={upcommingColumns} data={upcommingOrders} columnToShow={isMobile?3:upcommingColumns.length}/>
                    </Col>
                  
                    <Col align="start" gap="20px">
                        <Title dir={"start"} $isMobile={isMobile} $fontSize={20}>Recent Orders</Title>
                        {/* <DynamicTable data={orders} tableConfig={tableConfig} /> */}
                        <TableComponent idColumn="orderId" columns={ordersColumns} data={orders} columnToShow={isMobile?3:ordersColumns.length}/>
                        
                    </Col>

                  



                </Container>
        </>
    )
}

const FormBox = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    align-content: center;
    align-items: center;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    width: 350px;
    gap: 15px;
    padding: 10px;
  overflow: hidden; 
    /* Adding the semi-circle */
    &::before {
        content: '';
        position: absolute;
        top: -30px; /* Adjust the position as needed */
        right: -30px; /* Adjust the position as needed */
        width: 80px; /* Adjust the size as needed */
        height: 80px; /* Adjust the size as needed */
        background-color: ${Colors.primary}; /* The color of the semi-circle */
        border-radius: 50%;
    }
`;