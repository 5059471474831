import { useEffect, useState } from "react";
import { Col, Container, Img, Title, WrappedRow, Text, Btn, Row } from "../style";
import { Backgrounds, Icons, Images } from "../constants/images";
import styled from "styled-components";
import { Colors } from "../theme/colors";
import { FormComponent } from "../components/generic/Form";
import { FieldType, TextFieldType } from "../components/fields";
import Joi from "joi";
import { BaseLayout } from "../components/layout/BaseLayout";
import { useFormHandling } from "../hooks/FormHandle";
import { RequestConfigs } from "../api";
import { useLocation, useNavigate } from "react-router-dom";
import { useDeviceType } from "../context/DeviceContext";
import { PercentageProgressBar } from "../components/generic/ProgressBar";
import DynamicTable from "../components/generic/Table";
import { Products } from "../components/home/products";
import { useCustomer } from "../context/CustomerContext";
import { RoyaltyStats } from "../components/royalty/royaltyStats";
import { RoyaltyPoints } from "../components/royalty/royaltyPoints";
import { ShopData } from "../components/data/ShopData";
import { DataLayout } from "../components/data";
import { Shop } from "../components/shop";


export const ShopPage = () => {

    const location = useLocation()
    const { customer } = useCustomer()
    const { isMobile } = useDeviceType()

    useEffect(() => {
        if (location.hash) {
            const element = document.getElementById(location.hash.substring(1));
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [location]);


    return (
        <BaseLayout>
        <ContentArea $isMobile={isMobile}>
            <Shop/>
        </ContentArea>
            
        </BaseLayout>
    )




}


const ContentArea = styled.div<{$isMobile:boolean}>`
    margin-left: ${props=>props.$isMobile?"":"220px"};
    margin-right: ${props=>props.$isMobile?"":"220px"};
`