import React, { createContext, useContext, useEffect, useState } from 'react';

interface CustomerContextType {
  customer: Customer | null;
  setCustomer: React.Dispatch<React.SetStateAction<Customer | null>>;
}

const CustomerContext = createContext<CustomerContextType | undefined>(undefined);

export const useCustomer = (): CustomerContextType => {
  const context = useContext(CustomerContext);
  if (!context) {
    throw new Error('useCustomer must be used within an CustomerProvider');
  }
  return context;
};

interface CustomerProviderProps {
  children: React.ReactNode;
}

export const CustomerProvider = ({ children }: CustomerProviderProps) => {
  const [customer, setCustomer] = useState<Customer | null>(() => {
    // Retrieve the customer from localStorage if it exists
    const storedCustomer = localStorage.getItem('customer');
    return storedCustomer ? JSON.parse(storedCustomer) : null;
  });

  useEffect(() => {
    // Save the customer to localStorage whenever it changes
    if (customer !== null) {
      localStorage.setItem('customer', JSON.stringify(customer));
    } else {
      localStorage.removeItem('customer');
    }
  }, [customer]);

  // The value that will be provided to any descendant components
  const value: CustomerContextType = { customer, setCustomer };

  return (
    <CustomerContext.Provider value={value}>
      {children}
    </CustomerContext.Provider>
  );
};
