import { useEffect } from "react";
import { Col, Container, Img, Title, WrappedRow,Text, Btn } from "../style";
import { Icons, Images } from "../constants/images";
import styled from "styled-components";
import { Colors } from "../theme/colors";
import { FormComponent } from "../components/generic/Form";
import { FieldType, TextFieldType } from "../components/fields";
import Joi from "joi";
import { BaseLayout } from "../components/layout/BaseLayout";
import { useFormHandling } from "../hooks/FormHandle";
import { RequestConfigs } from "../api";
import { useNavigate } from "react-router-dom";
import { useDeviceType } from "../context/DeviceContext";

const formFields:FieldConfig[] = [
    {
        type:FieldType.text,
        label:"Email",
        name:"emailAddress",
        joiSchema:Joi.string().email({ tlds: { allow: false } }).required(),
        typeOptions:{
            type:TextFieldType.text
        }

    }
]
export const LoginPage = () => {
    const {handleInputChange,handleClick,submitted,serverRes} = useFormHandling({requestType:RequestConfigs.oneTimeLogin,fieldsConfig:formFields})
    const navigate = useNavigate()
    const {isMobile} = useDeviceType()
    useEffect(() => {

       if(serverRes?.succeeded && serverRes.token){
        navigate(`/auto-login?token=${serverRes.token}`)
       }
        
      }, [serverRes]);
    return (
        <>
        <BaseLayout>
        <Container>
                <Col>
                <FormBox $isMobile={isMobile}>
                        <Img src={Icons.Login} width={32}/>
                        <Title $isMobile={false} $fontSize={24} $lineHeight={35} weight={600}>Login Your Account</Title>
                        <FormComponent fields={formFields} submitted={submitted} onChange={handleInputChange} serverRes={serverRes}/>
                        <Btn to={"#"} onClick={handleClick}>Login</Btn>
                    </FormBox>
                </Col>
                

                 

                
            </Container>
        
        
        </BaseLayout>
        
        </>
        
    )
  
};

const FormBox = styled.div<{$isMobile:boolean}>`
    display: flex;
    position: relative;
    flex-direction: column;
    align-content: center;
    align-items: center;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    width: ${props=>props.$isMobile?"90%":"450px"};
    gap: 15px;
    padding: 10px;
    margin: 20px;
  overflow: hidden; 
    /* Adding the semi-circle */
    &::before {
        content: '';
        position: absolute;
        top: -30px; /* Adjust the position as needed */
        right: -30px; /* Adjust the position as needed */
        width: 80px; /* Adjust the size as needed */
        height: 80px; /* Adjust the size as needed */
        background-color: ${Colors.primary}; /* The color of the semi-circle */
        border-radius: 50%;
    }
`;
