import { useEffect, useState } from "react";
import { RequestConfigs } from "../../api";
import { Icons } from "../../constants/images";
import { useDeviceType } from "../../context/DeviceContext";
import { useFormHandling } from "../../hooks/FormHandle";
import { Container,Col,WrappedRow,Img,Title,Text, Row } from "../../style"
import { PercentageProgressBar } from "../generic/ProgressBar";
import DynamicTable from "../generic/Table";
import styled from "styled-components";
import { Colors } from "../../theme/colors";


export const RoyaltyPoints = ()=>{
    const {isMobile} = useDeviceType()
    const {  handleClick,  serverRes } = useFormHandling({ requestType: RequestConfigs.getRoyalty })
    const [points,setPoints] = useState<number>(0)
    const [credit,setCredit] = useState<number>(0)
    const [orders,setOrders] = useState<RoyaltyOrders[]>([])
    


    useEffect(() => {
        handleClick()
    }, []);


    useEffect(() => {
        if (serverRes?.succeeded && serverRes?.royaltyOrders) {
          
            setOrders(serverRes?.royaltyOrders.sort((a, b) => new Date(b.orderDate).getTime() - new Date(a.orderDate).getTime()))
        }
        if (serverRes?.succeeded && serverRes?.customer) {
            setPoints(Number(serverRes?.customer.custom2))
            setCredit(Number(serverRes?.customer.creditAmount))
        }
    }, [serverRes]);

    

    return (
        <Box>
          
                 
          <Col>
                                    <Row justify="flex-start">
                                    <Col align="start" $maxWidth="25px" justify="center" margin="0px 0px 3px 0px">
                                        <Img src={Icons.Star} width={20}/>
                                    </Col>
                                    <Col align="start" justify="center">
                                        <Text weight={600}  color={Colors.whiteBackground}><b>{points}</b> Points</Text>
                                    </Col>

                                    </Row>
                                    
                                    <Row justify="flex-start">
                                    <Col margin="10px 0px" $maxWidth="450px">
                                        <PercentageProgressBar value={points}/>
                                        <Text weight={500}  color={Colors.whiteBackground}>
                                            Collect <b>{100-points} points</b> to get <b>100$</b> for any future order
                                            
                                        </Text>
                                    </Col>
                                    </Row>
                                    <Row justify="flex-start">
                                    <Col align="start" $maxWidth="25px" justify="center" margin="3px 3px 0px 0px">
                                        <Img src={Icons.Cash} width={25}/>
                                    </Col>
                                    <Col align="start" justify="center">
                                        <Text weight={600}  color={Colors.whiteBackground}><b>{credit?"$"+credit:0}</b> Avaliable Credit</Text>
                                    </Col>
                                    

                                    </Row>
                                    
                                    
                                    </Col>


                
        </Box>
    )
}

const Box = styled.div`
    display: flex;
    background:linear-gradient(${Colors.primary}, #01010ced);
    padding: 15px;
    border: 1px solid ${Colors.primary};
    border-radius: 15px;
    margin: 20px 0px;
    
    
`;