import styled from "styled-components"
import { Col, Row, Text } from "../../style"
import { Colors } from "../../theme/colors"
import { PriceComponent } from "./priceComponent"

interface OfferItemProps {
    offerItem:ShopOffer
    image:string
    currency:string
    offerIndex:number
    currentIndex:number
    onSelect:(index:number)=>void
}

export const OfferItem = ({offerItem,image,currency,offerIndex,currentIndex,onSelect}:OfferItemProps)=>{
    const isSelected = offerIndex===currentIndex
    return (
        <OfferBox onClick={()=>onSelect(offerIndex)} $isSelected={isSelected}>
            <Row>
                <Col $maxWidth="35%">
                    {/* <ImgItem src={process.env.REACT_APP_API_ENDPOINT+image}/> */}
                    <ImgPack img={image} qty={offerItem.qty}/>
                </Col>
                <Col>
                    <Text color={isSelected?Colors.lightPrimary:Colors.blackText} $fontSize="14px" weight={600}>{offerItem.productName}</Text>
                    <PriceComponent price={offerItem.price} retailPrice={offerItem.retailPrice} currency={currency}/>
                </Col>
            </Row>

        </OfferBox>
    )

}

const OfferBox = styled.div<{$isSelected:boolean}>`
    display: flex;
    flex-direction: column;
    position: relative;
    border: 1px solid ${Colors.fieldBorder};
    padding: 10px;
    width: 350px;
    background: ${props=>props.$isSelected?Colors.primary:Colors.whiteBackground};
    border-radius: 10px;
    box-sizing: border-box;
    cursor: pointer;
`

const ImgItem = styled.img`
width: 30px;
`

const ImgPack = ({qty,img}:any)=>{
    if(qty===1){
        return (
       
            <AbsImgBox level={2} side={2}>
                <ImgItem src={process.env.REACT_APP_API_ENDPOINT+img}/>
            </AbsImgBox>
            
       
    )
    } else if(qty===2){
        return (
       <>
            <AbsImgBox level={2} side={1}>
                <ImgItem src={process.env.REACT_APP_API_ENDPOINT+img}/>
            </AbsImgBox>
            <AbsImgBox level={2} side={3}>
                <ImgItem src={process.env.REACT_APP_API_ENDPOINT+img}/>
            </AbsImgBox>
       </>
          
    )
    } else if(qty===3){
        return (
       <>
            <AbsImgBox level={1} side={2}>
                <ImgItem src={process.env.REACT_APP_API_ENDPOINT+img}/>
            </AbsImgBox>
            <AbsImgBox level={2} side={1}>
                <ImgItem src={process.env.REACT_APP_API_ENDPOINT+img}/>
            </AbsImgBox>
            <AbsImgBox level={2} side={3}>
                <ImgItem src={process.env.REACT_APP_API_ENDPOINT+img}/>
            </AbsImgBox>
       </>
          
    )
    } else if(qty===5){
        return (
       <>
            <AbsImgBox level={1} side={2}>
                <ImgItem src={process.env.REACT_APP_API_ENDPOINT+img}/>
            </AbsImgBox>
            <AbsImgBox level={2} side={1}>
                <ImgItem src={process.env.REACT_APP_API_ENDPOINT+img}/>
            </AbsImgBox>
            <AbsImgBox level={2} side={3}>
                <ImgItem src={process.env.REACT_APP_API_ENDPOINT+img}/>
            </AbsImgBox>
            <AbsImgBox level={3} side={0}>
                <ImgItem src={process.env.REACT_APP_API_ENDPOINT+img}/>
            </AbsImgBox>
            <AbsImgBox level={3} side={4}>
                <ImgItem src={process.env.REACT_APP_API_ENDPOINT+img}/>
            </AbsImgBox>
       </>
          
    )
    } else {
        return (<></>)
    }
    
}


const AbsImgBox =styled.div<{level:number,side:number}> `
display: flex;
flex-direction: column;
box-sizing: border-box;
position: absolute;
bottom: ${props=>props.level===1?0:(props.level===2?'10px':'25px')};
left: ${props=>props.side===0?'5px':(props.side===1?'20px':(props.side===2?'35px':(props.side===3?"50px":"65px")))};
z-index: ${props=>props.level===1?5:(props.level===2?3:1)};
`