import React from "react";
import { Col,Text } from "../../style"

interface SubInfoProps {
    name:string;
    price:string;
    day:string;
}

export const SubInfo:React.FC<SubInfoProps> = ({name,price,day})=>{
    
    return (
        <>
        <Col>
            <Text >By placing your monthly recurring order of {name}- you will be charged {price} Now and every {day} days thereafter until you cancel your subscription. You will receive an electronic notification 5 to 7 days prior to your transaction and receipt after each successful transaction.</Text>
        </Col>
        </>
    )
}