import { useEffect, useState } from "react";
import { Col, Container, Img, Title, WrappedRow,Text, Btn } from "../style";
import { Icons, Images } from "../constants/images";
import styled from "styled-components";
import { Colors } from "../theme/colors";
import { FormComponent } from "../components/generic/Form";
import { FieldType, TextFieldType } from "../components/fields";
import Joi from "joi";
import { BaseLayout } from "../components/layout/BaseLayout";
import { useFormHandling } from "../hooks/FormHandle";
import { RequestConfigs } from "../api";
import { useLocation } from "react-router-dom";
import ReactCodeInput ,{ ReactCodeInputProps } from 'react-code-input'

const formFields:FieldConfig[] = [
    {
        type:FieldType.text,
        label:"token",
        name:"token",
        joiSchema:Joi.string().required(),
        typeOptions:{
            type:TextFieldType.text
        }

    },
    {
        type:FieldType.text,
        label:"code",
        name:"code",
        joiSchema:Joi.string().required(),
        typeOptions:{
            type:TextFieldType.text
        }

    }
]

const props = {
    className: "reactCodeInput",
    inputStyle: {
      fontFamily: 'monospace',
      margin:  '4px',
      width: '15px',
      borderRadius: '3px',
      fontSize: '14px',
      height: '26px',
      paddingLeft: '7px',
      backgroundColor: 'black',
      color: 'lightskyblue',
      border: '1px solid lightskyblue'
    },
    inputStyleInvalid: {
      fontFamily: 'monospace',
      margin:  '4px',
      width: '15px',
      borderRadius: '3px',
      fontSize: '14px',
      height: '26px',
      paddingLeft: '7px',
      backgroundColor: 'black',
      color: 'red',
      border: '1px solid red'
    }
  }
export const AutoLoginPage = () => {
    const location = useLocation()
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('token');
    const codeParam = queryParams.get('code');
    const [code,setCode] = useState(codeParam)
    const {handleInputChange,handleClick,submitted,serverRes} = useFormHandling({requestType:RequestConfigs.autoLogin,fieldsConfig:[],data:{token,code},navOnSuccess:"/royalty"})
    useEffect(() => {

        if(code?.length===4){
            handleClick()        
        }
        
      }, [code]);

      const codeChange = (code:any)=>{
        console.log(code,"code")
   
      }
    return (
        <>
        <BaseLayout>
        <Container>
                <Col>
                <FormBox>
                        <Img src={Icons.Login} width={32}/>
                        <Title $isMobile={false} $fontSize={24} $lineHeight={35} weight={600}>One Time Password</Title>
                        <FormComponent fields={[]} onChange={()=>{}} serverRes={serverRes} submitted={submitted}/>
                        {/* <ReactCodeInput name="test" type='number' inputMode="numeric" fields={6} {...props}/> */}
                        <ReactCodeInput name="test" type='text' fields={4} inputMode="numeric" onChange={setCode}/>
                    </FormBox>
                </Col>
                

                 

                
            </Container>
        
        
        </BaseLayout>
        
        </>
        
    )
  
};

const FormBox = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    align-content: center;
    align-items: center;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    width: 450px;
    gap: 15px;
    padding: 10px;
  overflow: hidden; 
    /* Adding the semi-circle */
    &::before {
        content: '';
        position: absolute;
        top: -30px; /* Adjust the position as needed */
        right: -30px; /* Adjust the position as needed */
        width: 80px; /* Adjust the size as needed */
        height: 80px; /* Adjust the size as needed */
        background-color: ${Colors.primary}; /* The color of the semi-circle */
        border-radius: 50%;
    }
`;
