import { Container, Title,Text,Col } from "../../../style"

export const TermsUs = ()=>{

    return (
        <Container>
            <Col align="start" gap="40px">
                <Title $isMobile={false} $fontSize={22}>Terms & Conditions</Title>
                <Text>Last updated April 22, 2024</Text>
                <Col align="start" gap="10px">
                    <Title $isMobile={false} $fontSize={15}>OVERVIEW</Title>
                    <Text>
                        This website is operated by SlimmyGummy. Throughout the site, the terms “we”, “us” and “our” refer to SlimmyGummy. SlimmyGummy offers this website, including all information, tools and Services available from this site to you, the user, conditioned upon your acceptance of all terms, conditions, policies and notices stated here.
                    </Text>
                    <Text>
                        By visiting our site and/ or purchasing something from us, you engage in our “Service” and agree to be bound by the following terms and conditions (“Terms of Service”, “Terms”), including those additional terms and conditions and policies referenced herein and/or available by hyperlink. These Terms of Service apply to all users of the site, including without limitation users who are browsers, vendors, customers, merchants, and/ or contributors of content.
                    </Text>
                    <Text>
                        Please read these Terms of Service carefully before accessing or using our website. By accessing or using any part of the site, you agree to be bound by these Terms of Service. If you do not agree to all the terms and conditions of this agreement, then you may not access the website or use any Services. If these Terms of Service are considered an offer, acceptance is expressly limited to these Terms of Service.
                    </Text>
                    <Text>
                        Any new features or tools which are added to the current store shall also be subject to the Terms of Service. You can review the most current version of the Terms of Service at any time on this page. We reserve the right to update, change or replace any part of these Terms of Service by posting updates and/or changes to our website. It is your responsibility to check this page periodically for changes. Your continued use of or access to the website following the posting of any changes constitutes acceptance of those changes.
                    </Text>
                </Col>
                
                <Col align="start" gap="10px">
                    <Title $isMobile={false} $fontSize={15}>SECTION 1 - ONLINE STORE TERMS</Title>
                    <Text>
                        By agreeing to these Terms of Service, you represent that you are at least the age of majority in your state or province of residence, or that you are the age of majority in your state or province of residence and you have given us your consent to allow any of your minor dependents to use this site. You may not use our products for any illegal or unauthorized purpose nor may you, in the use of the Service, violate any laws in your jurisdiction (including but not limited to copyright laws).
                    </Text>
                    <Text>
                        These Legal Terms constitute a legally binding agreement made between you, whether personally or on behalf of an entity, and www.SlimmyGummy.co, concerning your access to and use of the Services. You agree that by accessing the Services, you have read, understood, and agreed to be bound by all of these Legal Terms. IF YOU DO NOT AGREE WITH ALL OF THESE LEGAL TERMS, THEN YOU ARE EXPRESSLY PROHIBITED FROM USING THE SERVICES AND YOU MUST DISCONTINUE USE IMMEDIATELY.
                    </Text>
                    <Text>
                        A breach or violation of any of the Terms will result in an immediate termination of your Services.
                    </Text>
                </Col>

                <Col align="start" gap="10px">
                    <Title $isMobile={false} $fontSize={15}>SECTION 2 - GENERAL CONDITIONS</Title>
                    <Text>
                        We reserve the right to refuse Service to anyone for any reason at any time.
                    </Text>
                    <Text>
                        You understand that your content (not including credit card information), may be transferred unencrypted and involve (a) transmissions over various networks; and (b) changes to conform and adapt to technical requirements of connecting networks or devices. Credit card information is always encrypted during transfer over networks.
                    </Text>
                    <Text>
                        You agree not to reproduce, duplicate, copy, sell, resell or exploit any portion of the Service, use of the Service, or access to the Service or any contact on the website through which the Service is provided, without express written permission by us.
                    </Text>
                    <Text>
                        The headings used in this agreement are included for convenience only and will not limit or otherwise affect these Terms.
                    </Text>
                </Col>

                <Col align="start" gap="10px">
                    <Title $isMobile={false} $fontSize={15}>SECTION 3 - ACCURACY, COMPLETENESS AND TIMELINESS OF INFORMATION</Title>
                    <Text>
                        We are not responsible if information made available on this site is not accurate, complete or current. The material on this site is provided for general information only and should not be relied upon or used as the sole basis for making decisions without consulting primary, more accurate, more complete or more timely sources of information. Any reliance on the material on this site is at your own risk.
                    </Text>
                    <Text>
                        This site may contain certain historical information. Historical information, necessarily, is not current and is provided for your reference only. We reserve the right to modify the contents of this site at any time, but we have no obligation to update any information on our site. You agree that it is your responsibility to monitor changes to our site.
                    </Text>
                </Col>

                <Col align="start" gap="10px">
                    <Title $isMobile={false} $fontSize={15}>SECTION 4 - MODIFICATIONS TO THE SERVICE AND PRICES</Title>
                    <Text>
                        Prices for our products are subject to change without notice.
                    </Text>
                    <Text>
                        We reserve the right at any time to modify or discontinue the Service (or any part or content thereof) without notice at any time.
                    </Text>
                    <Text>
                        We shall not be liable to you or to any third-party for any modification, price change, suspension or discontinuance of the Service.
                    </Text>
                </Col>

                <Col align="start" gap="10px">
                    <Title $isMobile={false} $fontSize={15}>SECTION 5 - PRODUCTS OR SERVICES (if applicable)</Title>
                    <Text>
                        Certain products or Services may be available exclusively online through the website. These products or Services may have limited quantities and are subject to return or exchange only according to our Refund Policy.
                    </Text>
                    <Text>
                        We have made every effort to display as accurately as possible the colors and images of our products that appear at the store. We cannot guarantee that your computer monitor's display of any color will be accurate.
                    </Text>
                    <Text>
                        We reserve the right, but are not obligated, to limit the sales of our products or Services to any person, geographic region or jurisdiction. We may exercise this right on a case-by-case basis. We reserve the right to limit the quantities of any products or Services that we offer. All descriptions of products or product pricing are subject to change at anytime without notice, at the sole discretion of us. We reserve the right to discontinue any product at any time. Any offer for any product or Service made on this site is void where prohibited.
                    </Text>
                    <Text>
                        We do not warrant that the quality of any products, Services, information, or other material purchased or obtained by you will meet your expectations, or that any errors in the Service will be corrected.
                    </Text>
                </Col>

                <Col align="start" gap="10px">
                    <Title $isMobile={false} $fontSize={15}>SECTION 5.b - Automatic Refill Service Terms</Title>
                    <Text>
                        All chosen packages are part of our unique wellness journey monthly subscription program. To ensure that your experience is not interrupted, approximately 5 days before the renewal of your chosen package subscription cycle, you will receive a refill notification email to notify you that a fresh refill of the product and quantity that you originally ordered is being prepared to be shipped. You agree to be charged the same low price per bottle of the product as in your original order. You will be charged the same price for a lower quantity of bottles, excluding the free bottles from your original chosen package. 
                    </Text>
                    <Text >* By placing your monthly recurring order of Keto+AVC (2 pack) MRP- you will be charged $119.95 Now and every 30 days thereafter until you cancel your subscription. You will receive an electronic notification 5 to 7 days prior to your transaction and receipt after each successful transaction.</Text>
                    <Text>* By placing your monthly recurring order of Keto+AVC (2+1 Free) MRP- you will be charged $159.95 Now and every 30 days thereafter until you cancel your subscription. You will receive an electronic notification 5 to 7 days prior to your transaction and receipt after each successful transaction.</Text>
                    <Text>* By placing your monthly recurring order of Keto+AVC (3+2 Free) MRP- you will be charged $199.95 Now and every 30 days thereafter until you cancel your subscription. You will receive an electronic notification 5 to 7 days prior to your transaction and receipt after each successful transaction.</Text>
                </Col>

                <Col align="start" gap="10px">
                    <Title $isMobile={false} $fontSize={15}>SECTION 5.c - Promotional and Add-On Offers</Title>
                    <Text>
                        After you place an order on this website you may be offered promotional and add-on offers on our order confirmation page and by email. You will have the opportunity to take advantage of these promotional and add-on offers by selecting those that you wish to purchase. These offers may include similar products at discounted rate, upgrades in dosage strengths for the products that you had originally ordered, or express shipping upgrades. If you choose to select a promotion or add-on offer, you will be charged for them in a second subsequent transaction. We will make every effort to ship all products that you order within a 24 hour time frame in the same package as your original order. 
                    </Text>
                </Col>

                <Col align="start" gap="10px">
                    <Title $isMobile={false} $fontSize={15}>SECTION 5.d – Delivery, cancel, return and refund service terms</Title>
                    <Title $isMobile={false} $fontSize={14}>Delivery </Title>
                    <Text>
                        Your product will ship within 24-48 hours and should arrive within 5-10 business days. If your product does not arrive within the allotted amount of time, please contact customer service to address the delay. Although we always strive to make our deliveries as timely as possible, circumstances may arise outside of our control, such as inclement weather, natural disasters, or other postal delays, which may impede the timely arrival of your product. You agree we are not liable for such delays. You agree to pay for taxes, shipping or carriage of Products as such costs arise. Delivery timescales/dates specified on the Site, in any order acknowledgement, acceptance or elsewhere are estimates only. While We endeavour to meet such timescales or dates, We do not undertake to dispatch Products and/or commence Services by a particular date or dates and shall not be liable to You in respect of delays or failure to do so. IF OUR PRODUCTS FAIL TO ARRIVE IN THE ESTIMATED DELIVERY TIME OF 5-10 BUSINESS DAYS, PLEASE CONTACT OUR CUSTOMER SERVICE LINE AND LET THEM KNOW.
                    </Text>
                    <Text>
                        Delivery shall be to a valid address within the Territory submitted by You and subject to Acceptance ("Delivery Address"). You must check the Delivery Address on any acknowledgement or acceptance We provide and notify Us without delay of errors or omissions. We reserve the right to charge You for any extra costs arising from changes You make to the Delivery Address after You submit an order.
                    </Text>
                    <Text>
                        Delivery services shall be provided by USPS and/or FEDEX. Delivery services provider will be chosen randomly or by date of estimated soonest delivery if available, otherwise a 3PL service will be used. Tracking shall be shared with You by email If available at the time of order pickup by delivery services and within up to 48 hours from order pickup.
                    </Text>
                    <Text>
                        We shall not be liable, and You shall not be entitled to reject Products or Services. You take full liability to track the delivery of your order with a tracking number provided you over email once it is shipped, communicating with the shipping company or carrier and inquiring about any logistical aspect of your order.    
                    </Text>

                    <Title $isMobile={false} $fontSize={14}>Cancelation</Title>
                    <Text>
                        To cancel your subscription – you must contact customer support in writing over email. Support@slimmygummy.co
                    </Text>

                    <Title $isMobile={false} $fontSize={14}>Return</Title>
                    {/* <Text>
                        We have a 30-day return policy, which means you have 30 days after receiving your item to request a return. To be eligible for a return, your item must be in the same condition that you received it, unworn or unused, with tags, and in its original packaging. You’ll also need the receipt or proof of purchase.
                    </Text> */}
                    <Text>
                        We have a 30-day return policy, which means you have 30 days after receiving your item to request a return. To be eligible for a return, your item must be in the same condition as when you received it, unworn or unused, with tags, and in its original packaging. Opened bottles are eligible for return only if their contents have not been used. You’ll also need the receipt or proof of purchase.
                    </Text>
                    <Text>
                        To start a return, you can contact us at support@slimmygummy.co to begin the process, receive an RMA - Return Merchandise Authorization number, and we'll give you instructions on how to return your order to our warehouse. Items sent back to us without first requesting a return will not be accepted. You can always contact us for any return question at support@slimmygummy.co.
                    </Text>
                    <Text>
                        Return Address: 11407 SW Amu St Suite #BVJ304-RET Tualatin, OR 97062 USA
                    </Text>
                    <Text>
                        For any type of order cancelation or update, you are required to contact us at support@slimmygummy.co
                    </Text>
                    <Text>
                        Unfortunately, we cannot accept returns on sale items.
                    </Text>

                    <Title $isMobile={false} $fontSize={14}>Refunds</Title>
                    <Text>
                        We will notify you once we’ve received and inspected your return, and let you know if the refund was approved or not. If approved, you’ll be automatically refunded on your original payment method within 10 business days. Please remember it can take some time for your bank or credit card company to process and post the refund too. If more than 15 business days have passed since we’ve approved your return, please contact us at support@slimmygummy.co
                    </Text>

                </Col>

                <Col align="start" gap="10px">
                    <Title $isMobile={false} $fontSize={15}>SECTION 6 - ACCURACY OF BILLING AND ACCOUNT INFORMATION</Title>
                    <Text>
                        We reserve the right to refuse any order you place with us. We may, in our sole discretion, limit or cancel quantities purchased per person, per household or per order. These restrictions may include orders placed by or under the same customer account, the same credit card, and/or orders that use the same billing and/or shipping address. In the event that we make a change to or cancel an order, we may attempt to notify you by contacting the e‑mail and/or billing address/phone number provided at the time the order was made. We reserve the right to limit or prohibit orders that, in our sole judgment, appear to be placed by dealers, resellers or distributors.
                    </Text>
                    <Text>
                        You agree to provide current, complete and accurate purchase and account information for all purchases made at our store. You agree to promptly update your account and other information, including your email address and credit card numbers and expiration dates, so that we can complete your transactions and contact you as needed.
                    </Text>
                </Col>

                <Col align="start" gap="10px">
                    <Title $isMobile={false} $fontSize={15}>SECTION 7 - OPTIONAL TOOLS</Title>
                    <Text>
                        We may provide you with access to third-party tools over which we neither monitor nor have any control nor input.
                    </Text>
                    <Text>
                        You acknowledge and agree that we provide access to such tools ”as is” and “as available” without any warranties, representations or conditions of any kind and without any endorsement. We shall have no liability whatsoever arising from or relating to your use of optional third-party tools.
                    </Text>
                    <Text>
                        Any use by you of the optional tools offered through the site is entirely at your own risk and discretion and you should ensure that you are familiar with and approve of the terms on which tools are provided by the relevant third-party provider(s).
                    </Text>
                    <Text>
                        We may also, in the future, offer new Services and/or features through the website (including the release of new tools and resources). Such new features and/or Services shall also be subject to these Terms of Service.
                    </Text>
                </Col>

                <Col align="start" gap="10px">
                    <Title $isMobile={false} $fontSize={15}>SECTION 8 - THIRD-PARTY LINKS</Title>
                    <Text>
                        Certain content, products and Services available via our Service may include materials from third-parties.
                    </Text>
                    <Text>
                        Third-party links on this site may direct you to third-party websites that are not affiliated with us. We are not responsible for examining or evaluating the content or accuracy and we do not warrant and will not have any liability or responsibility for any third-party materials or websites, or for any other materials, products, or Services of third-parties.
                    </Text>
                    <Text>
                        We are not liable for any harm or damages related to the purchase or use of goods, Services, resources, content, or any other transactions made in connection with any third-party websites. Please review carefully the third-party's policies and practices and make sure you understand them before you engage in any transaction. Complaints, claims, concerns, or questions regarding third-party products should be directed to the third-party.
                    </Text>
                </Col>

                <Col align="start" gap="10px">
                    <Title $isMobile={false} $fontSize={15}>SECTION 9 - USER COMMENTS, FEEDBACK AND OTHER SUBMISSIONS</Title>
                    <Text>
                        If, at our request, you send certain specific submissions (for example contest entries) or without a request from us, you send creative ideas, suggestions, proposals, plans, or other materials, whether online, by email, by postal mail, or otherwise (collectively, 'comments'), you agree that we may, at any time, without restriction, edit, copy, publish, distribute, translate and otherwise use in any medium any comments that you forward to us. We are and shall be under no obligation (1) to maintain any comments in confidence; (2) to pay compensation for any comments; or (3) to respond to any comments.
                    </Text>
                    <Text>
                        We may, but have no obligation to, monitor, edit or remove content that we determine in our sole discretion to be unlawful, offensive, threatening, libelous, defamatory, pornographic, obscene or otherwise objectionable or violates any party’s intellectual property or these Terms of Service.
                    </Text>
                    <Text>
                        You agree that your comments will not violate any right of any third-party, including copyright, trademark, privacy, personality or other personal or proprietary right. You further agree that your comments will not contain libelous or otherwise unlawful, abusive or obscene material, or contain any computer virus or other malware that could in any way affect the operation of the Service or any related website. You may not use a false e‑mail address, pretend to be someone other than yourself, or otherwise mislead us or third-parties as to the origin of any comments. You are solely responsible for any comments you make and their accuracy. We take no responsibility and assume no liability for any comments posted by you or any third-party.
                    </Text>
                </Col>

                <Col align="start" gap="10px">
                    <Title $isMobile={false} $fontSize={15}>SECTION 10 - PERSONAL INFORMATION</Title>
                    <Text>
                        Your submission of personal information through the store is governed by our Privacy Policy.
                    </Text>
                </Col>

                <Col align="start" gap="10px">
                    <Title $isMobile={false} $fontSize={15}>SECTION 11 - ERRORS, INACCURACIES AND OMISSIONS</Title>
                    <Text>
                        Occasionally there may be information on our site or in the Service that contains typographical errors, inaccuracies or omissions that may relate to product descriptions, pricing, promotions, offers, product shipping charges, transit times and availability. We reserve the right to correct any errors, inaccuracies or omissions, and to change or update information or cancel orders if any information in the Service or on any related website is inaccurate at any time without prior notice (including after you have submitted your order).
                    </Text>
                    <Text>
                        We undertake no obligation to update, amend or clarify information in the Service or on any related website, including without limitation, pricing information, except as required by law. No specified update or refresh date applied in the Service or on any related website, should be taken to indicate that all information in the Service or on any related website has been modified or updated.
                    </Text>
                </Col>

                <Col align="start" gap="10px">
                    <Title $isMobile={false} $fontSize={15}>SECTION 12 - PROHIBITED USES</Title>
                    <Text>
                        In addition to other prohibitions as set forth in the Terms of Service, you are prohibited from using the site or its content: (a) for any unlawful purpose; (b) to solicit others to perform or participate in any unlawful acts; (c) to violate any international, federal, provincial or state regulations, rules, laws, or local ordinances; (d) to infringe upon or violate our intellectual property rights or the intellectual property rights of others; (e) to harass, abuse, insult, harm, defame, slander, disparage, intimidate, or discriminate based on gender, sexual orientation, religion, ethnicity, race, age, national origin, or disability; (f) to submit false or misleading information; (g) to upload or transmit viruses or any other type of malicious code that will or may be used in any way that will affect the functionality or operation of the Service or of any related website, other websites, or the Internet; (h) to collect or track the personal information of others; (i) to spam, phish, pharm, pretext, spider, crawl, or scrape; (j) for any obscene or immoral purpose; or (k) to interfere with or circumvent the security features of the Service or any related website, other websites, or the Internet. We reserve the right to terminate your use of the Service or any related website for violating any of the prohibited uses.
                    </Text>
                </Col>

                <Col align="start" gap="10px">
                    <Title $isMobile={false} $fontSize={15}>SECTION 13 - DISCLAIMER OF WARRANTIES; LIMITATION OF LIABILITY</Title>
                    <Text>
                        We do not guarantee, represent or warrant that your use of our Service will be uninterrupted, timely, secure or error-free.
                    </Text>
                    <Text>
                        We do not warrant that the results that may be obtained from the use of the Service will be accurate or reliable.
                    </Text>
                    <Text>
                        You agree that from time to time we may remove the Service for indefinite periods of time or cancel the Service at any time, without notice to you.
                    </Text>
                    <Text>
                        You expressly agree that your use of, or inability to use, the Service is at your sole risk. The Service and all products and Services delivered to you through the Service are (except as expressly stated by us) provided 'as is' and 'as available' for your use, without any representation, warranties or conditions of any kind, either express or implied, including all implied warranties or conditions of merchantability, merchantable quality, fitness for a particular purpose, durability, title, and non-infringement.
                    </Text>
                    <Text>
                        In no case shall SlimmyGummy, our directors, officers, employees, affiliates, agents, contractors, interns, suppliers, Service providers or licensors be liable for any injury, loss, claim, or any direct, indirect, incidental, punitive, special, or consequential damages of any kind, including, without limitation lost profits, lost revenue, lost savings, loss of data, replacement costs, or any similar damages, whether based in contract, tort (including negligence), strict liability or otherwise, arising from your use of any of the Service or any products procured using the Service, or for any other claim related in any way to your use of the Service or any product, including, but not limited to, any errors or omissions in any content, or any loss or damage of any kind incurred as a result of the use of the Service or any content (or product) posted, transmitted, or otherwise made available via the Service, even if advised of their possibility. Because some states or jurisdictions do not allow the exclusion or the limitation of liability for consequential or incidental damages, in such states or jurisdictions, our liability shall be limited to the maximum extent permitted by law.
                    </Text>
                </Col>

                <Col align="start" gap="10px">
                    <Title $isMobile={false} $fontSize={15}>SECTION 14 - INDEMNIFICATION</Title>
                    <Text>
                        You agree to indemnify, defend and hold harmless SlimmyGummy and our parent, subsidiaries, affiliates, partners, officers, directors, agents, contractors, licensors, Service providers, subcontractors, suppliers, interns and employees, harmless from any claim or demand, including reasonable attorneys’ fees, made by any third-party due to or arising out of your breach of these Terms of Service or the documents they incorporate by reference, or your violation of any law or the rights of a third-party.
                    </Text>
                </Col>

                <Col align="start" gap="10px">
                    <Title $isMobile={false} $fontSize={15}>SECTION 15 - SEVERABILITY</Title>
                    <Text>
                        In the event that any provision of these Terms of Service is determined to be unlawful, void or unenforceable, such provision shall nonetheless be enforceable to the fullest extent permitted by applicable law, and the unenforceable portion shall be deemed to be severed from these Terms of Service, such determination shall not affect the validity and enforceability of any other remaining provisions.
                    </Text>
                </Col>

                <Col align="start" gap="10px">
                    <Title $isMobile={false} $fontSize={15}>SECTION 16 - TERMINATION</Title>
                    <Text>
                        The obligations and liabilities of the parties incurred prior to the termination date shall survive the termination of this agreement for all purposes.
                    </Text>
                    <Text>
                        These Terms of Service are effective unless and until terminated by either you or us. You may terminate these Terms of Service at any time by notifying us that you no longer wish to use our Services, or when you cease using our site.
                    </Text>
                    <Text>
                        If in our sole judgment you fail, or we suspect that you have failed, to comply with any term or provision of these Terms of Service, we also may terminate this agreement at any time without notice and you will remain liable for all amounts due up to and including the date of termination; and/or accordingly may deny you access to our Services (or any part thereof).
                    </Text>
                </Col>

                <Col align="start" gap="10px">
                    <Title $isMobile={false} $fontSize={15}>SECTION 17 - ENTIRE AGREEMENT</Title>
                    <Text>
                        The failure of us to exercise or enforce any right or provision of these Terms of Service shall not constitute a waiver of such right or provision.
                    </Text>
                    <Text>
                        These Terms of Service and any policies or operating rules posted by us on this site or in respect to the Service constitutes the entire agreement and understanding between you and us and governs your use of the Service, superseding any prior or contemporaneous agreements, communications and proposals, whether oral or written, between you and us (including, but not limited to, any prior versions of the Terms of Service).
                    </Text>
                    <Text>
                        Any ambiguities in the interpretation of these Terms of Service shall not be construed against the drafting party.
                    </Text>
                </Col>

                <Col align="start" gap="10px">
                    <Title $isMobile={false} $fontSize={15}>SECTION 18 - GOVERNING LAW</Title>
                    <Text>
                        These Terms of Service and any separate agreements whereby we provide you Services shall be governed by and construed in accordance with the laws of United States.
                    </Text>
                </Col>

                <Col align="start" gap="10px">
                    <Title $isMobile={false} $fontSize={15}>SECTION 19 - CHANGES TO TERMS OF SERVICE</Title>
                    <Text>
                        You can review the most current version of the Terms of Service at any time at this page.
                    </Text>
                    <Text>
                        We reserve the right, at our sole discretion, to update, change or replace any part of these Terms of Service by posting updates and changes to our website. It is your responsibility to check our website periodically for changes. Your continued use of or access to our website or the Service following the posting of any changes to these Terms of Service constitutes acceptance of those changes.
                    </Text>
                </Col>


            </Col>
            
        </Container>
        
    )

    
}